import React from "react";

import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./components/login/login";
import QRForm from "./components/qrform/qrform";
import Signup from "./components/signup/signup";
import Profile from "./pages/profile/profile";
import Dashboard from "./pages/dashboard/dashboard";
import EditQR from "./components/editqr/editqr";
import axios from "axios";
import PrivateRoutes from "./routes/PrivateRoutes";
import ForgotPassword from "./components/forgotpassword/forgotpassword";
import ResetPassword from "./components/resetpassword/resetpassword";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/qrform" element={<QRForm />} />
            <Route path="/edit-qrinfo/:id" element={<EditQR />} />
          </Route>
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
