import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import qr from "../../assets/images/qrscan.png";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [notify, setNotify] = useState({
    show: false,
    error: false,
    message: "",
  });

  const submit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/api/forgot-password", { email });

      setNotify({
        show: true,
        error: false,
        message: "Email has been sent!",
      });
    } catch (e) {
      setNotify({
        show: true,
        error: true,
        message: "Email does not exist!",
      });
    }
  };

  let info;

  if (notify.show) {
    info = (
      <div
        className={notify.error ? "alert alert-danger" : "alert alert-success"}
        role="alert"
      >
        {notify.message}
      </div>
    );
  }

  return (
    <>
      <div className="login-page-container">
        <div className="login-left">
          <div className="curve"></div>
          <div className="curve2"></div>
          <div className="curve3"></div>
          <div className="left-design">
            <span className="qr-gentext">QR Code Generator</span>
            <img className="qr-img" src={qr} alt="Scan your QR Now" />
          </div>
          <div className="powered">
            <h4>Powered by Sciever Inc.</h4>
          </div>
        </div>

        <div className="login-right">
          <div className="login-form-wrapper">
            <div className="login-form">
              <div className="login-head">
                <span className="loginSubHead" style={{ marginBottom: 20 }}>
                  Forgot your Password?
                </span>
              </div>
              <div className="login-body">
                <form onSubmit={submit}>
                  <div className="login-form-group">
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <span>{info}</span>

                  <div className="login-form-group">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="login-btn"
                      fullWidth
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      Send email
                    </Button>
                  </div>
                </form>

                <div className="login_wrap_login">
                  <div className="login-item">
                    <span style={{ color: "#828282" }}>
                      Not Registered Yet?
                      <Link
                        to={"/signup"}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          marginLeft: 5,
                        }}
                      >
                        Create an account
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
