import React from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Facebook from "@mui/icons-material/Facebook";
import google from "../../assets/images/google-logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";

function SocialLogin() {
  const navigate = useNavigate();

  async function facebookLogin(data) {
    axios.post(`/api/facebook-login`, data).then((res) => {
      if (res.data.status === 200) {
        localStorage.setItem("auth_token", res.data.token);
        alert("Successful login");
        navigate("/dashboard");
      } else {
        alert("Login failed");
      }
    });
  }

  async function googleLogin(data) {
    axios.post(`/api/google-login`, data).then((res) => {
      if (res.data.status === 200) {
        localStorage.setItem("auth_token", res.data.token);
        alert("Successful login");
        navigate("/dashboard");
      } else {
        console.log("No login");
      }
    });
  }

  function googleLoginError(e) {
    console.log("Login failed");
  }

  return (
    <div style={{ width: "100%" }}>
      <div>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          autoLoad={false}
          callback={facebookLogin}
          render={(renderProps) => (
            <div onClick={renderProps.onClick}>
              <Button
                variant="outlined"
                style={{
                  height: 45,
                  marginBottom: 10,
                  border: "1px solid #e8e8e8",
                  textTransform: "none",
                }}
                fullWidth
              >
                <Facebook style={{ color: "#4064AC", marginRight: 10 }} />
                <span style={{ color: "#828282" }}> Log in with Facebook</span>
              </Button>
            </div>
          )}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_APP_ID}
          render={(renderProps) => (
            <div onClick={renderProps.onClick}>
              <Button
                variant="outlined"
                style={{
                  height: 45,
                  marginBottom: 10,
                  border: "1px solid #e8e8e8",
                  textTransform: "none",
                }}
                fullWidth
              >
                <img
                  style={{
                    marginRight: 10,
                    width: "1.7rem",
                    height: "1.7rem",
                  }}
                  src={google}
                  alt="Google icon"
                />
                <span style={{ color: "#828282" }}> Log in with Google</span>
              </Button>
            </div>
          )}
          onSuccess={googleLogin}
          onFailure={googleLoginError}
          cookiePolicy={"single_host_origin"}
        />
      </div>
    </div>
  );
}

export default SocialLogin;
