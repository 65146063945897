import Sidebar from "../../components/sidebar/sidebar";
import QRList from "../../components/qrlist/qrlist";

export default function Dashboard() {
  return (
    <>
      <Sidebar />
      <QRList />
    </>
  );
}
