import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./login.css";
import qr from "../../assets/images/qrscan.png";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import SocialLogin from "../social-login";

export default function Login() {
  const navigate = useNavigate();

  const [loginInput, setLoginInput] = useState({
    email: "",
    password: "",
    error_list: [],
  });

  const handleInput = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    };

    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/login`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          alert("Logged in successfully", res.data.message);
          navigate("/dashboard");
        } else if (res.data.status === 401) {
        } else {
          setLoginInput({
            ...loginInput,
            error_list: res.data.validation_errors,
          });
        }
      });
    });
  };

  return (
    <>
      <div className="login-page-container">
        <div className="login-left">
          <div className="curve"></div>
          <div className="curve2"></div>
          <div className="curve3"></div>
          <div className="left-design">
            <span className="qr-gentext">QR Code Generator</span>
            <img className="qr-img" src={qr} alt="Scan your QR Now" />
          </div>
          <div className="powered">
            <h4>Powered by Sciever Inc.</h4>
          </div>
        </div>
        <div className="login-right">
          <div className="login-form-wrapper">
            <div className="login-form">
              <div className="login-head">
                <span className="loginSubHead" style={{ marginBottom: 36 }}>
                  Login to your Account
                </span>

                {/* ------------------------------------------------------------------------------- */}
                <SocialLogin />
                {/* ----------------------------------------------------------------------------------- */}

                <span
                  style={{
                    fontSize: 12,
                    color: "#dddddd",
                    marginTop: 16,
                    marginBottom: 24,
                  }}
                >
                  ----------or Sign in with Email----------
                </span>
              </div>
              <div className="login-body">
                <form onSubmit={loginSubmit}>
                  <div className="login-form-group">
                    <TextField
                      label="Email"
                      type="email"
                      name="email"
                      onChange={handleInput}
                      value={loginInput.email}
                      variant="outlined"
                      placeholder="mail@abc.com"
                      fullWidth
                    />
                    <span style={{ fontSize: 12, color: "red" }}>
                      {loginInput.error_list.email}
                    </span>
                  </div>
                  <div className="login-form-group">
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={loginInput.password}
                      variant="outlined"
                      placeholder="*******************"
                      fullWidth
                    />
                    <span style={{ fontSize: 12, color: "red" }}>
                      {loginInput.error_list.password}
                    </span>
                  </div>
                  <div className="login-password-txt">
                    <label
                      role="presentation"
                      htmlFor="password"
                      className="forgotPwd"
                      style={{ fontSize: 12, marginBottom: 15 }}
                    >
                      <Link to="/forgot" style={{ textDecoration: "none" }}>
                        Forgot Password?
                      </Link>
                    </label>
                  </div>
                  <div className="login-form-group">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="login-btn"
                      fullWidth
                    >
                      Login
                    </Button>
                  </div>
                </form>
                <div className="login_wrap_login">
                  <div className="login-item">
                    <span style={{ color: "#828282" }}>
                      Not Registered Yet?
                      <Link
                        to={"/signup"}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          marginLeft: 5,
                        }}
                      >
                        Create an account
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
