import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import qr from "../../assets/images/qrscan.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

function ResetPassword(props) {
  const navigate = useNavigate();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);

  const submit = async (e) => {
    e.preventDefault();

    await axios.post("/api/reset-password", {
      token,
      password,
      password_confirm: passwordConfirm,
    });

    setRedirect(true);
  };

  if (redirect) {
    navigate("/");
  }

  return (
    <>
      <div className="login-page-container">
        <div className="login-left">
          <div className="curve"></div>
          <div className="curve2"></div>
          <div className="curve3"></div>
          <div className="left-design">
            <span className="qr-gentext">QR Code Generator</span>
            <img className="qr-img" src={qr} alt="Scan your QR Now" />
          </div>
          <div className="powered">
            <h4>Powered by Sciever Inc.</h4>
          </div>
        </div>

        <div className="login-right">
          <div className="login-form-wrapper">
            <div className="login-form">
              <div className="login-head">
                <span className="loginSubHead" style={{ marginBottom: 20 }}>
                  Reset your Password
                </span>
              </div>
              <div className="login-body">
                <form onSubmit={submit}>
                  <div className="login-form-group">
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="login-form-group">
                    <TextField
                      label="Confirm Password"
                      type="password"
                      name="confirm_password"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                  </div>

                  <div className="login-form-group">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="login-btn"
                      fullWidth
                      style={{ marginTop: 10, marginBottom: 20 }}
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>

                <div className="login_wrap_login">
                  <div className="login-item">
                    <span style={{ color: "#828282" }}>
                      Not Registered Yet?
                      <Link
                        to={"/signup"}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          marginLeft: 5,
                        }}
                      >
                        Create an account
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
