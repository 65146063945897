import { useState, useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Button } from "@mui/material";

const QrCode = (props) => {
  const [url, setUrl] = useState(
    process.env.REACT_APP_BASE_URL + `/profile/${props.id}`
  );

  const qrRef = useRef();

  const downloadQRCode = (e) => {
    e.preventDefault();
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    setUrl("");
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div ref={qrRef} style={{ marginRight: 30 }}>
        <QRCodeCanvas id="qrCode" value={url} size={200} level={"H"} />
      </div>
      <div>
        <Button
          style={{ borderColor: "#777777", borderRadius: 0, marginTop: 50 }}
          size="large"
          variant="outlined"
          onClick={(event) => downloadQRCode(event)}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default QrCode;
