import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../login/login.css";
import qr from "../../assets/images/qrscan.png";
import { useNavigate } from "react-router-dom";
import SocialLogin from "../social-login";

export default function Signup() {
  const navigate = useNavigate();
  const [registerInput, setRegisterInput] = useState({
    name: "",
    email: "",
    password: "",
    error_list: [],
  });

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setRegisterInput({ ...registerInput, [e.target.name]: e.target.value });
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    const data = {
      name: registerInput.name,
      email: registerInput.email,
      password: registerInput.password,
    };
    axios.get("/sanctum/csrf-cookie").then((response) => {
      axios.post(`/api/register`, data).then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("auth_token", res.data.token);
          localStorage.setItem("auth_name", res.data.username);
          alert("Registered successfully", res.data.message);
          navigate("/dashboard");
        } else {
          setRegisterInput({
            ...registerInput,
            error_list: res.data.validation_errors,
          });
        }
      });
    });
  };

  return (
    <>
      <div className="login-page-container">
        <div className="login-left">
          <div className="curve"></div>
          <div className="curve2"></div>
          <div className="curve3"></div>
          <div className="left-design">
            <span className="qr-gentext">QR Code Generator</span>
            <img className="qr-img" src={qr} alt="Scan your QR Now" />
          </div>
          <div className="powered">
            <h4>Powered by Sciever Inc.</h4>
          </div>
        </div>
        <div className="login-right">
          <div className="login-form-wrapper">
            <div className="login-form">
              <div className="login-head">
                <span className="loginSubHead" style={{ marginBottom: 24 }}>
                  Create your Account
                </span>

                {/* ------------------------------------------------------------------------------------- */}
                <SocialLogin />
                {/* -------------------------------------------------------------------------------------- */}

                <span
                  style={{
                    fontSize: 12,
                    color: "#dddddd",
                    marginTop: 16,
                    marginBottom: 24,
                  }}
                >
                  ----------or Create an Account with the form below----------
                </span>
              </div>
              <div className="login-body">
                <form onSubmit={registerSubmit}>
                  <div className="login-form-group">
                    <TextField
                      label="Full Name"
                      type=""
                      name="name"
                      onChange={handleInput}
                      value={registerInput.name}
                      fullWidth
                      variant="outlined"
                    />
                    <span style={{ fontSize: 12, color: "red" }}>
                      {registerInput.error_list.name}
                    </span>
                  </div>
                  <div className="login-form-group">
                    <TextField
                      label="Email"
                      type=""
                      name="email"
                      onChange={handleInput}
                      value={registerInput.email}
                      fullWidth
                      variant="outlined"
                      placeholder="mail@abc.com"
                    />
                    <span style={{ fontSize: 12, color: "red" }}>
                      {registerInput.error_list.email}
                    </span>
                  </div>
                  <div className="login-form-group">
                    <TextField
                      label="Password"
                      type="password"
                      name="password"
                      onChange={handleInput}
                      value={registerInput.password}
                      fullWidth
                      variant="outlined"
                      placeholder="*******************"
                    />
                    <span style={{ fontSize: 12, color: "red" }}>
                      {registerInput.error_list.password}
                    </span>
                  </div>
                  {/* <div className="login-form-group">
                    <TextField
                      id="confirmPassword"
                      label="Confirm Password"
                      type="confirmPassword"
                      name="confirm_password"
                      value={password}
                      fullWidth
                      variant="outlined"
                      placeholder="*******************"
                    />
                  </div> */}

                  <div className="login-form-group">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="login-btn"
                      fullWidth
                      style={{ marginTop: 10 }}
                    >
                      Signup
                    </Button>
                  </div>
                </form>
                <div className="login_wrap_login">
                  <div className="login-item">
                    <span style={{ color: "#828282" }}>
                      Already have an account?
                      <Link
                        to={"/"}
                        style={{
                          textDecoration: "none",
                          color: "blue",
                          marginLeft: 5,
                        }}
                      >
                        Login
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
