import Navbar from "../../components/navbar/navbar";

import {
  Button,
  Divider,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import SendIcon from "@mui/icons-material/Send";
import PlaceIcon from "@mui/icons-material/Place";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LanguageIcon from "@mui/icons-material/Language";
import avatar from "../../assets/images/ava.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function EditQR() {
  const params = useParams();

  const navigate = useNavigate();
  const [qrInfo, setQrInfo] = useState({
    qrname: "",
    fullname: "",
    mobile: "",
    telephone: "",
    email: "",
    company: "",
    job: "",
    street: "",
    city: "",
    state: "",
    country: "",
    website: "",
    description: "",
  });

  const [picture, setPicture] = useState([]);
  const [error, setError] = useState([]);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQrInfo({ ...qrInfo, [name]: value });
  };

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  const updateInfo = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", picture.image);
    formData.append("qrname", qrInfo.qrname);
    formData.append("fullname", qrInfo.fullname);
    formData.append("mobile", qrInfo.mobile);
    formData.append("telephone", qrInfo.telephone);
    formData.append("email", qrInfo.email);
    formData.append("company", qrInfo.company);
    formData.append("job", qrInfo.job);
    formData.append("street", qrInfo.street);
    formData.append("city", qrInfo.city);
    formData.append("state", qrInfo.state);
    formData.append("country", qrInfo.country);
    formData.append("website", qrInfo.website);
    formData.append("description", qrInfo.description);

    axios.post(`/api/update-qrinfo/${params.id}`, formData).then((res) => {
      if (res.status === 200) {
        setError([]);
        navigate("/dashboard");
      } else if (res.status === 422) {
        alert("Mandatory fields aren't filled");
        setError(res.data.errors);
      } else if (res.status === 404) {
        alert(res.data.message);
        navigate("/dashboard");
      }
    });
  };

  useEffect(() => {
    axios.get(`/api/edit-qrinfo/${params.id}`).then((res) => {
      if (res.data.status === 200) {
        setQrInfo(res.data.qrinfo);
      } else if (res.data.status === 404) {
        alert("No items of that id");
        navigate("/dashboard");
      }
    });
  }, [params.id, navigate]);

  return (
    <>
      <Navbar />

      <div className="form-preview-container">
        <div className="form-left">
          <div className="form-wrapper">
            <form onSubmit={updateInfo} encType="multipart/form-data">
              <div className="form-header">
                <div className="header-title">
                  <TextField
                    style={{ width: "100%" }}
                    name="qrname"
                    label="Name your QR Code"
                    required
                    onChange={handleInput}
                    value={qrInfo.qrname}
                  />
                  <small style={{ color: "red" }}>{error.qrname}</small>
                </div>
              </div>
              <div style={{ padding: 20 }}>
                <div>
                  <div className="personal-details">
                    <p style={{ marginBottom: 20 }}>
                      Fill in your details according to the form below. Not all
                      fields are mandatory.
                    </p>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          <label>Image: </label>
                          <input
                            type="file"
                            name="image"
                            onChange={handleImage}
                          />
                        </div>
                        <div>
                          <img
                            src={`http://localhost:8000/${qrInfo.image}`}
                            alt="profile"
                            width="100px"
                          />
                        </div>
                        <small style={{ color: "red" }}>{error.image}</small>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="fullname"
                          label="Full Name"
                          value={qrInfo.fullname}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.fullname}</small>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="mobile"
                          label="Mobile Number"
                          value={qrInfo.mobile}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.mobile}</small>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="telephone"
                          label="Telephone"
                          value={qrInfo.telephone}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="email"
                          label="Email"
                          value={qrInfo.email}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.email}</small>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="company"
                          label="Company"
                          value={qrInfo.company}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="job"
                          label="Job Title"
                          value={qrInfo.job}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </Grid>

                      <Divider
                        variant="middle"
                        style={{ marginTop: 20, width: "100%" }}
                      />

                      <Grid item xs={12}>
                        <span>Address</span>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="street"
                          label="Street Address"
                          value={qrInfo.street}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.street}</small>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="city"
                          label="City"
                          value={qrInfo.city}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.city}</small>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="state"
                          label="State"
                          value={qrInfo.state}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.state}</small>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          name="country"
                          label="Country"
                          value={qrInfo.country}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                        <small style={{ color: "red" }}>{error.country}</small>
                      </Grid>

                      <Divider
                        variant="middle"
                        style={{ marginTop: 20, width: "100%" }}
                      />

                      <Grid item xs={12}>
                        <TextField
                          name="website"
                          label="Website"
                          value={qrInfo.website}
                          onChange={handleInput}
                          variant="outlined"
                          required
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextareaAutosize
                          style={{
                            width: "100%",
                            height: 70,
                            border: "1px solid #d2d2d2",
                            borderRadius: "4px",
                            fontFamily: "inherit",
                          }}
                          name="description"
                          label="Description"
                          value={qrInfo.description}
                          placeholder=" Write your description"
                          onChange={handleInput}
                        />
                      </Grid>
                    </Grid>
                  </div>

                  <div className="form-btn">
                    <Button
                      type="submit"
                      style={{ marginBottom: "10px" }}
                      variant="contained"
                    >
                      Update your Info
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="preview-right">
          <Button
            variant="contained"
            sx={{ marginBottom: 5, borderRadius: 10, paddingX: 6 }}
          >
            Preview
          </Button>
          <div className="preview-wrapper">
            <div style={{ marginBottom: 20 }}>
              <div className="main-profile">
                <div className="main-profile-top">
                  <img className="ava-img" src={avatar} alt="avatar" />
                  {/* <img className="ava-img" src={picture.image} alt="avatar" /> */}
                  <Typography
                    sx={{ marginTop: 3, marginBottom: 1 }}
                    variant="h5"
                  >
                    {qrInfo.fullname}
                  </Typography>
                  <Typography>{qrInfo.job}</Typography>
                </div>
                <Divider style={{ width: "100%", backgroundColor: "#fff" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{ textAlign: "center", padding: 5, width: "106px" }}
                  >
                    <PhoneIcon />
                    <Typography style={{ fontSize: 12 }}>CALL</Typography>
                  </div>
                  <Divider
                    style={{ backgroundColor: "#fff" }}
                    orientation="vertical"
                    flexItem
                  />
                  <div
                    style={{ textAlign: "center", padding: 5, width: "106px" }}
                  >
                    <SendIcon />
                    <Typography style={{ fontSize: 12 }}>EMAIL</Typography>
                  </div>
                  <Divider
                    style={{ backgroundColor: "#fff" }}
                    orientation="vertical"
                    flexItem
                  />
                  <div
                    style={{ textAlign: "center", padding: 5, width: "106px" }}
                  >
                    <PlaceIcon />
                    <Typography style={{ fontSize: 12 }}>DIRECTIONS</Typography>
                  </div>
                </div>
              </div>
              <div className="add-info">
                <div style={{ marginLeft: 45 }}>
                  <Typography>{qrInfo.description}</Typography>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <PhoneIcon style={{ color: "#777777", marginRight: 20 }} />

                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{qrInfo.mobile}</Typography>
                    <Typography style={{ color: "#777777", marginBottom: 10 }}>
                      Mobile
                    </Typography>
                    <Typography>{qrInfo.telephone}</Typography>
                    <Typography style={{ color: "#777777" }}>
                      Telephone
                    </Typography>
                    <Divider
                      component="flex"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <MailOutlineIcon
                    style={{ color: "#777777", marginRight: 20 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{qrInfo.email}</Typography>
                    <Typography style={{ color: "#777777" }}>Email</Typography>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <WorkOutlineIcon
                    style={{ color: "#777777", marginRight: 20 }}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{qrInfo.company}</Typography>
                    <Typography style={{ color: "#777777" }}>
                      {qrInfo.job}
                    </Typography>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <PlaceIcon style={{ color: "#777777", marginRight: 20 }} />
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{qrInfo.street}</Typography>
                    <Typography>{qrInfo.city}</Typography>
                    <Typography>{qrInfo.state}</Typography>
                    <Typography>{qrInfo.country}</Typography>
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <LanguageIcon style={{ color: "#777777", marginRight: 20 }} />
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography>{qrInfo.website}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
