import { Box, Button, Typography, Divider, Grid, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import QrCode from "../generator/qrgenerator";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const QRList = () => {
  const [loading, setLoading] = useState(true);
  const [viewQr, setViewQr] = useState([]);

  useEffect(() => {
    axios.get(`/api/view-qrs`).then((res) => {
      if (res.data.status === 200) {
        setViewQr(res.data.qrs);
        setLoading(false);
      }
    });
  }, []);

  const deleteQR = (e, id) => {
    e.preventDefault();

    axios.delete(`/api/delete-qr/${id}`).then((res) => {
      if (res.data.status === 200) {
        alert(res.data.message);
        axios.get(`/api/view-qrs`).then((res) => {
          if (res.data.status === 200) {
            setViewQr(res.data.qrs);
          }
        });
      } else if (res.data.status === 404) {
        alert(res.data.message);
      }
    });
  };

  var display_Qrdata = "";
  if (loading) {
    return (
      <Typography
        style={{ position: "absolute", top: 150, left: 350 }}
        variant="h4"
      >
        Loading...
      </Typography>
    );
  } else {
    display_Qrdata = viewQr.map((item) => {
      return (
        <Grid xs={12} style={{ marginBottom: 30 }}>
          <Paper
            style={{
              display: "flex",
              padding: 20,
              flexDirection: "row",
              maxWidth: 1050,
              position: "relative",
            }}
            elevation={2}
          >
            <Box
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Link
                to={`/profile/${item.id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  style={{
                    color: "#777777",
                    display: "flex",
                    alignItems: "center",
                  }}
                  variant="h5"
                  gutterBottom
                >
                  <CreditCardIcon style={{ marginRight: 5 }} />
                  {item.qrname}
                </Typography>
              </Link>
              <Link
                to={`/profile/${item.id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#777777",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  <AccessTimeIcon style={{ marginRight: 5 }} />
                  {item.created_at.replace("T", " ").split(".")[0]}
                </Typography>
              </Link>
              <Link to={`/edit-qrinfo/${item.id}`}>
                <Button
                  variant="outlined"
                  style={{
                    position: "absolute",
                    bottom: 80,
                    right: 90,
                  }}
                >
                  <EditIcon />
                </Button>
              </Link>
              <Button
                variant="outlined"
                style={{ position: "absolute", bottom: 80, right: 20 }}
                onClick={(e) => deleteQR(e, item.id)}
              >
                <DeleteIcon />
              </Button>
            </Box>
            <Divider
              style={{ marginRight: 65 }}
              orientation="vertical"
              variant="middle"
              flexItem
            />
            <Box style={{ display: "flex", alignItems: "center" }}>
              <QrCode id={item.id} />
            </Box>
          </Paper>
        </Grid>
      );
    });
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        marginLeft: { xs: "0px", md: "340px" },
        marginTop: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 5,
          maxWidth: 1050,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography style={{ fontWeight: "700" }}>
            Existing QR Codes
          </Typography>
        </Box>
        <Box>
          <Link
            to={"/qrform"}
            style={{
              textDecoration: "none",
            }}
          >
            <Button style={{ backgroundColor: "#90c52d" }} variant="contained">
              <AddIcon sx={{ fontSize: 20, marginRight: "10px" }} /> Create QR
              Code
            </Button>
          </Link>
        </Box>
      </Box>
      {display_Qrdata}
    </Box>
  );
};

export default QRList;
