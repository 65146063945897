import { useState, useEffect } from "react";
import axios from "axios";
import { Divider, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import SendIcon from "@mui/icons-material/Send";
import PlaceIcon from "@mui/icons-material/Place";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LanguageIcon from "@mui/icons-material/Language";
import React from "react";
import "./profile.css";
import { useParams } from "react-router-dom";

export default function Profile(props) {
  const [profile, setProfile] = useState([]);
  const params = useParams();

  useEffect(() => {
    axios.get(`/api/qr-profile/${params.id}`).then((res) => {
      if (res.data.status === 200) {
        setProfile(res.data.profile);
      }
    });
  }, [params.id]);

  return (
    <div className="profile-wrapper">
      <div style={{ marginBottom: 20 }}>
        <div className="main_profile">
          <div className="main-profile-top">
            <img
              className="ava_img"
              src={`http://localhost:8000/${profile.image}`}
              alt="avatar"
            />
            <Typography sx={{ marginTop: 3, marginBottom: 1 }} variant="h5">
              {profile.fullname}
            </Typography>
            <Typography>{profile.job}</Typography>
          </div>
          <Divider style={{ width: "100%", backgroundColor: "#fff" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ textAlign: "center", padding: 5, width: "106px" }}>
              <PhoneIcon />
              <Typography style={{ fontSize: 12 }}>CALL</Typography>
            </div>
            <Divider
              style={{ backgroundColor: "#fff" }}
              orientation="vertical"
              flexItem
            />
            <div style={{ textAlign: "center", padding: 5, width: "106px" }}>
              <SendIcon />
              <Typography style={{ fontSize: 12 }}>EMAIL</Typography>
            </div>
            <Divider
              style={{ backgroundColor: "#fff" }}
              orientation="vertical"
              flexItem
            />
            <div style={{ textAlign: "center", padding: 5, width: "106px" }}>
              <PlaceIcon />
              <Typography style={{ fontSize: 12 }}>DIRECTIONS</Typography>
            </div>
          </div>
        </div>
        <div className="add_info">
          <div style={{ marginLeft: 45 }}>
            <Typography>{profile.description}</Typography>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <PhoneIcon style={{ color: "#777777", marginRight: 20 }} />

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Typography>{profile.mobile}</Typography>
              <Typography style={{ color: "#777777", marginBottom: 10 }}>
                Mobile
              </Typography>
              <Typography>{profile.telephone}</Typography>
              <Typography style={{ color: "#777777" }}>Telephone</Typography>
              <Divider
                component="flex"
                style={{ marginTop: 10, marginBottom: 10 }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <MailOutlineIcon style={{ color: "#777777", marginRight: 20 }} />
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Typography>{profile.email}</Typography>
              <Typography style={{ color: "#777777" }}>Email</Typography>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <WorkOutlineIcon style={{ color: "#777777", marginRight: 20 }} />

            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Typography>{profile.company}</Typography>
              <Typography style={{ color: "#777777" }}>
                {profile.job}
              </Typography>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <PlaceIcon style={{ color: "#777777", marginRight: 20 }} />
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Typography>{profile.street}</Typography>
              <Typography>{profile.city}</Typography>
              <Typography>{profile.state}</Typography>
              <Typography>{profile.country}</Typography>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <LanguageIcon style={{ color: "#777777", marginRight: 20 }} />
            <div
              style={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Typography>{profile.website}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
