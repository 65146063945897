import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";

function PrivateRoutes({ ...rest }) {
  const navigate = useNavigate();
  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`/api/checkingAuthenticated`).then((res) => {
      if (res.status === 200) {
        setAuthenticated(true);
      }
      setLoading(false);
    });

    return () => {
      setAuthenticated(false);
    };
  }, []);

  axios.interceptors.response.use(
    undefined,
    function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        alert("Unauthorized");
        navigate("/");
      }
      return Promise.reject(err);
    }
  );

  if (loading) {
    return console.log("Loading");
  }

  return Authenticated ? <Outlet /> : navigate("/");
}

export default PrivateRoutes;
